import React, { useState, useEffect } from 'react';
import { Button, Card, Col, FloatingLabel, Form, InputGroup, Row, Table } from 'react-bootstrap';
import { useCart } from "react-use-cart";
import BreadcrumbView from '../other/HtmlTag/BreadcrumbView';
import CurrencyFormat from 'react-currency-format';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import '../cart/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faCartShopping, faCheck, faMinus, faPlus, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import IsLoading from '../other/IsLoading';

const CartIndex = (props) => {
    const { isEmpty, cartTotal, totalUniqueItems, items, updateItemQuantity, removeItem, emptyCart, metadata, updateCartMetadata } = useCart();

    const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [optionDistricts, setOptionDistricts] = useState([]);
    const [wards, setWards] = useState([]);
    const [optionWards, setOptionWards] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const [isLoadSubmit, setIsLoadSubmit] = useState(false);
    const [isLoadDiscount, setisLoadDiscount] = useState(false);

    useEffect(() => {
        document.title = "Giỏ hàng";
        createCartMetadata();
        getLocations();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const createCartMetadata = () => {
        if (isEmpty) {
            updateCartMetadata({
                customer_name: '',
                customer_phone: '',
                customer_email: '',
                province_id: '',
                district_id: '',
                ward_id: '',
                customer_address: '',
                contract_desc: '',
                discount_code: '',
                discount_money: 0,
                discount_type: '',
                discount_value: '',
                totalMoney: cartTotal,
                error_list: [],
            });
        }
    }

    const updateQuantity = (id, quantity) => {
        if (quantity <= 0) {
            swal({
                title: "Bạn muốn xóa sản phẩm?",
                icon: "error",
                dangerMode: true,
                buttons: ["Đóng", "Xóa"],
            }).then((ok) => {
                if (ok) {
                    updateItemQuantity(id, quantity);
                }
            });
        } else {
            updateItemQuantity(id, quantity);
        }

        // Update Discount
        let data = { ...metadata };
        if (data.discount_code !== '' && data.discount_type !== '' && data.discount_value !== '') {
            if (data.discount_type === 0) {
                data.discount_money = cartTotal * data.discount_value / 100;
            } else {
                data.discount_money = data.discount_value;
            }
            updateCartMetadata(data);
        }
    }

    const destroyCart = () => {
        swal({
            title: "Bạn muốn xóa giỏ hàng?",
            icon: "error",
            dangerMode: true,
            buttons: ["Đóng", "Xóa"],
        }).then((ok) => {
            if (ok) {
                return emptyCart();
            }
        });
    }

    const deleteProduct = (id) => {
        swal({
            title: "Bạn muốn xóa sản phẩm?",
            icon: "error",
            dangerMode: true,
            buttons: ["Đóng", "Xóa"],
        }).then((ok) => {
            if (ok) {
                return removeItem(id);
            }
        });
    }

    const getLocations = () => {
        setIsLoad(true);
        axios.get(`/getLocations`).then((res) => {
            if (res.status === 200) {
                setProvinces(res.data.provinces);
                setDistricts(res.data.districts);
                setWards(res.data.wards);

                let districtShow = res.data.districts.filter(item => item.province_id === parseInt(metadata.province_id));
                setOptionDistricts(districtShow);

                let wardShow = res.data.wards.filter(item => item.district_id === parseInt(metadata.district_id));
                setOptionWards(wardShow);
            }
            setIsLoad(false);
        }).catch((error) => {
            toast(error.message, { type: 'error' });
            setIsLoad(false);
        });
    }

    const handleInput = (e) => {
        let field = e.target.name;
        let value = e.target.value;

        let data = { ...metadata };

        data[field] = value;

        if (value.length) {
            delete (data.error_list[field]);
        }

        if (field === 'province_id') {
            // const provinceData = provinces.filter(item => item.id === parseInt(value));
            // data['feeshipping'] = provinceData[0].freeship;

            let districtShow = districts.filter(item => item.province_id === parseInt(value));

            setOptionDistricts(districtShow);
            setOptionWards([]);
            data.district_id = '';
            data.ward_id = '';
        } else if (field === 'district_id') {
            // const provinceData = provinces.filter(item => item.id === parseInt(metadata.province_id));
            // const districtData = districts.filter(item => item.id === parseInt(value));


            let wardShow = wards.filter(item => item.district_id === parseInt(value) && item.province_id === parseInt(metadata.province_id));
            setOptionWards(wardShow);
            data.ward_id = '';
        } else if (field === 'discount_code') {
            data.discount_type = '';
            data.discount_value = '';
            data.discount_money = 0;
        }

        updateCartMetadata(data);
    }

    const checkCustomer = (e) => {
        const phone = e.target.value;
        if (phone.length > 0) {
            axios.get(`/checkCustomerPhone/${phone}`).then((res) => {
                if (res.status === 200) {
                    let data = { ...metadata };

                    if (res.data.status) {
                        let districtShow = districts.filter(item => item.province_id === parseInt(res.data.customer.province_id));
                        setOptionDistricts(districtShow);

                        let wardShow = wards.filter(item => item.district_id === parseInt(res.data.customer.district_id) && item.province_id === parseInt(res.data.customer.province_id));
                        setOptionWards(wardShow);

                        data.customer_name = res.data.customer.customer_name;
                        data.customer_phone = res.data.customer.customer_phone;
                        data.customer_email = res.data.customer.customer_email;
                        data.province_id = res.data.customer.province_id;
                        data.district_id = res.data.customer.district_id;
                        data.ward_id = res.data.customer.ward_id;
                        data.customer_address = res.data.customer.customer_address;
                        data.error_list = [];
                    } else {
                        data.customer_name = '';
                        data.customer_email = '';
                        data.province_id = '';
                        data.district_id = '';
                        data.ward_id = '';
                        data.customer_address = '';
                        data.error_list = [];
                        setOptionDistricts([]);
                        setOptionWards([]);
                    }

                    updateCartMetadata(data);
                }
            }).catch((error) => {
                toast(error.message, { type: 'error' });
            });
        }
    }

    const CustomerInfo = () => {
        return (<>
            <Card.Title className="mb-3"><i className='fa-solid fa-user' /> Thông tin khách hàng</Card.Title>
            <Row>
                <Col xs={6}>
                    <FloatingLabel label={<>Số điện thoại <i className='text-danger'>*</i></>} >
                        <Form.Control type="text" value={metadata.customer_phone} name="customer_phone" onChange={handleInput} onBlur={checkCustomer} placeholder="Số điện thoại" className={metadata.error_list.customer_phone ? 'is-invalid' : ''} disabled={isLoadSubmit} required />
                    </FloatingLabel>
                    <i className='text-danger'>{metadata.error_list.customer_phone}</i>
                </Col>

                <Col xs={6}>
                    <FloatingLabel label={<>Email </>} >
                        <Form.Control type="email" value={metadata.customer_email} name="customer_email" onChange={handleInput} placeholder="Email" className={metadata.error_list.customer_email ? 'is-invalid' : ''} disabled={isLoadSubmit} />
                    </FloatingLabel>
                    <i className='text-danger'>{metadata.error_list.customer_email}</i>
                </Col>

                <Col md={12}>
                    <FloatingLabel label={<>Họ và tên <i className='text-danger'>*</i></>} >
                        <Form.Control type="text" value={metadata.customer_name} name="customer_name" onChange={handleInput} placeholder="Họ và tên" className={metadata.error_list.customer_name && 'is-invalid'} disabled={isLoadSubmit} required />
                    </FloatingLabel>
                    <i className='text-danger'>{metadata.error_list.customer_name}</i>
                </Col>

                <Col md={6}>
                    <FloatingLabel label={<>Thành phố/Tỉnh <i className='text-danger'>*</i></>} >
                        <Form.Select value={metadata.province_id} name="province_id" onChange={handleInput} disabled={provinces.length > 0 && !isLoadSubmit ? false : true} className={metadata.error_list.province_id ? 'is-invalid' : ''} required >
                            <option value=''>--Chọn--</option>
                            {provinces.length > 0 ? provinces.map((item, i) => {
                                return <option key={i} value={item.id}>{item.prefix ? item.prefix + ' ' : null}{item.name}</option>
                            }) : <></>}
                        </Form.Select>
                    </FloatingLabel>
                    <i className='text-danger'>{metadata.error_list.province_id}</i>
                </Col>

                <Col md={6}>
                    <FloatingLabel label={<>Quận/Huyện <i className='text-danger'>*</i></>} >
                        <Form.Select value={metadata.district_id} name="district_id" onChange={handleInput} disabled={optionDistricts.length > 0 && !isLoadSubmit ? false : true} className={metadata.error_list.district_id ? 'is-invalid' : ''} required >
                            <option value=''>--Chọn--</option>
                            {optionDistricts.map((item, i) => {
                                return <option key={i} value={item.id}>{item.prefix ? item.prefix + ' ' : null}{item.name}</option>
                            })}
                        </Form.Select>
                    </FloatingLabel>
                    <i className='text-danger'>{metadata.error_list.district_id}</i>
                </Col>

                <Col md={6}>
                    <FloatingLabel label={<>Phường/Xã <i className='text-danger'>*</i></>} >
                        <Form.Select value={metadata.ward_id} name="ward_id" onChange={handleInput} disabled={optionWards.length > 0 && !isLoadSubmit ? false : true} className={metadata.error_list.ward_id ? 'is-invalid' : ''} required>
                            <option value=''>--Chọn--</option>
                            {optionWards.map((item, i) => {
                                return <option key={i} value={item.id}>{item.prefix ? item.prefix + ' ' : null}{item.name}</option>
                            })}
                        </Form.Select>
                    </FloatingLabel>
                    <i className='text-danger'>{metadata.error_list.ward_id}</i>
                </Col>

                <Col md={6}>
                    <FloatingLabel label={<>Địa chỉ <i className='text-danger'>*</i></>} >
                        <Form.Control type="text" value={metadata.customer_address} name="customer_address" onChange={handleInput} placeholder="Địa chỉ" disabled={metadata.ward_id !== '' && !isLoadSubmit ? false : true} className={metadata.error_list.customer_address ? 'is-invalid' : ''} required />
                    </FloatingLabel>
                    <i className='text-danger'>{metadata.error_list.customer_address}</i>
                </Col>

                <Col md={12}>
                    <FloatingLabel label="Lời nhắn" >
                        <Form.Control as="textarea" value={metadata.contract_desc} name="contract_desc" onChange={handleInput} placeholder="Lời nhắn" style={{ height: '100px' }} className={metadata.error_list.contract_desc ? 'is-invalid' : ''} disabled={isLoadSubmit} />
                    </FloatingLabel>
                    <i className='text-danger'>{metadata.error_list.contract_desc}</i>
                </Col>
            </Row>
        </>)
    }

    const handleCheckDiscount = () => {
        let data = { ...metadata };
        const discount_code = metadata.discount_code;
        if (metadata.discount_code.length === 0) {
            // data.error_list.discount_code = 'Không được bỏ trống.';
            updateCartMetadata(data);
        } else {
            setisLoadDiscount(true);
            axios.get(`/checkDiscountCode/${discount_code}`).then((res) => {
                if (res.status === 200) {
                    if (res.data.status) {
                        let data = { ...metadata };
                        const discount = res.data.discount;

                        data.discount_code = discount.discount_code;
                        data.discount_type = discount.discount_type;
                        data.discount_value = discount.discount_value;

                        if (discount.discount_type === 0) {
                            data.discount_money = cartTotal * discount.discount_value / 100;
                        } else {
                            data.discount_money = discount.discount_value;
                        }

                        updateCartMetadata(data);

                        toast(res.data.message, { type: 'success' });
                    } else {
                        toast(res.data.message, { type: 'error' });

                    }
                }
                setisLoadDiscount(false);
            }).catch((error) => {
                toast(error.message, { type: 'error' });
                setisLoadDiscount(false);
            });
        }


    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let formData = new FormData();

        formData.append('customer_name', metadata.customer_name);
        formData.append('customer_phone', metadata.customer_phone);
        formData.append('customer_email', metadata.customer_email);
        formData.append('province_id', metadata.province_id);
        formData.append('district_id', metadata.district_id);
        formData.append('ward_id', metadata.ward_id);
        formData.append('customer_address', metadata.customer_address);
        formData.append('contract_desc', metadata.contract_desc);
        formData.append('discount_code', metadata.discount_code);
        formData.append('payment_status', 2); //1 bank transfers, 2: cash
        formData.append('cart', JSON.stringify(items));

        swal({
            title: "Bạn chắc muốn đặt hàng?",
            icon: "warning",
            successMode: true,
            buttons: ["Đóng", "Đồng ý"],
        }).then((ok) => {
            if (ok) {
                setIsLoadSubmit(true);
                axios.post(`/saveCart`, formData).then((res) => {
                    if (res.status === 200) {
                        if (res.data.status === 401) {
                            toast(res.data.message, { type: 'error' });
                            setIsLoadSubmit(false);
                        } else if (res.data.status === 200) {
                            return sendEmail(res.data.contract_id);
                        } else {
                            toast("Vui lòng kiểm tra lại thông tin.", { type: "error" });
                            let data = { ...metadata };
                            data.error_list = res.data.validation_errors || [];
                            updateCartMetadata(data);
                            setIsLoadSubmit(false);
                        }
                    }
                }).catch((error) => {
                    toast(error.message, { type: 'error' });
                    setIsLoadSubmit(false);
                });
            }
        });
    }

    const sendEmail = (contract_id) => {
        axios.get(`/sendEmail/${contract_id}`).then((res) => {
            if (res.status === 200) {
                setIsLoadSubmit(false);
                swal({
                    title: "Đặt hàng thành công!",
                    text: 'Cảm ơn bạn đã đặt hàng! Chúng tôi sẽ trực tiếp liên hệ với bạn trong thời gian sớm nhất.',
                    icon: "success",
                    successMode: true,
                    buttons: "Đóng",
                }).then((ok) => {
                    emptyCart();
                })
            }
        }).catch((error) => {
            toast(error.message, { type: 'error' });
        });
    }

    return (
        <>
            <IsLoading show={isLoadSubmit} />

            <div className="mb-4" id="featured_cart">
                <BreadcrumbView link={[
                    { name: 'Trang chủ', active: false, href: `${props.page === 'order' ? '/order/' : '/'}` },
                    { name: 'Giỏ hàng', active: true }
                ]} />

                <Card variant="info" className='featured_cart mt-2' >
                    <Card.Header>
                        <Card.Title className='text-primary'>
                            <FontAwesomeIcon icon={faCartShopping} /> Giỏ hàng
                        </Card.Title>
                    </Card.Header>
                    {!isEmpty ?
                        <>
                            <Card.Body className='featured_cart_product'>
                                <Table bordered responsive  >
                                    <tbody>
                                        {items.map((item, i) => (
                                            <tr key={item.id}>
                                                <td className='text-center cart-item-img'>
                                                    {!isLoadSubmit ?
                                                        <button type='button' onClick={() => deleteProduct(item.id)} title='Xóa'>
                                                            x
                                                        </button>
                                                        : null
                                                    }

                                                    {item.proImg !== null
                                                        ? <img className='rounded' src={item.proImg} alt={item.proName} width='100%' />
                                                        : <img className='rounded' src="/assets/images/picture.png" alt={item.proName} width='100%' />
                                                    }
                                                </td>

                                                <td className='cart-item-title'>
                                                    <span className='main-title'>
                                                        <Link className='product__item__title' to={`${props.page === 'order' ? '/order' : ''}/san-pham/${item.proSlug}`} title="Xem sản phẩm">
                                                            {item.proName}
                                                        </Link>
                                                    </span>

                                                    <span className='sub-title'>{item.proType}</span>
                                                    <span className='item-unit'>Đơn vị: {item.proUnit}</span>
                                                </td>

                                                <td className='text-end'>
                                                    <CurrencyFormat value={item.price * item.quantity} displayType={'text'} thousandSeparator={true} suffix='₫' renderText={value => <span className='fw-bold'>{value}</span>} /><br></br>

                                                    {!isLoadSubmit ?
                                                        <InputGroup className='justify-content-end flex-nowrap my-1'>
                                                            <Button variant='outline-secondary' onClick={(e) => updateQuantity(item.id, parseInt(item.quantity) - 1)}>
                                                                <FontAwesomeIcon icon={faMinus} />
                                                            </Button>
                                                            <Form.Control className='text-end' type='number' min="0" value={item.quantity} onChange={(e) => updateQuantity(item.id, e.target.value)} style={{ maxWidth: '72px', minWidth: '72px' }} />
                                                            <Button variant='outline-secondary' onClick={(e) => updateQuantity(item.id, parseInt(item.quantity) + 1)}>
                                                                <FontAwesomeIcon icon={faPlus} />
                                                            </Button>
                                                        </InputGroup>
                                                        : <div>Số lượng: <strong>{item.quantity}</strong></div>
                                                    }

                                                    <CurrencyFormat value={item.price} displayType={'text'} thousandSeparator={true} prefix='Giá: ' suffix='₫' renderText={value => <span>{value}</span>} /><br></br>
                                                    {item.priceOld > 0 ?
                                                        <CurrencyFormat value={item.priceOld} displayType={'text'} thousandSeparator={true} suffix='₫' renderText={value => <span className="text-muted fst-italic text-decoration-line-through">{value}</span>} />
                                                        : <></>
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                    <tfoot>
                                        <tr>
                                            <td>
                                                {!isEmpty && !isLoadSubmit ?
                                                    <Button variant="outline-danger" onClick={destroyCart} >
                                                        <FontAwesomeIcon icon={faTrash} /> Xóa
                                                    </Button>
                                                    : <></>
                                                }
                                            </td>
                                            <td className='text-end fw-bold'>
                                                Tổng cộng ({totalUniqueItems} sp): <br></br>
                                            </td>
                                            <td className='text-end'>
                                                <CurrencyFormat value={cartTotal} displayType={'text'} thousandSeparator={true} suffix='₫' renderText={value => <span className='fw-bold'>{value}</span>} /><br></br>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </Card.Body>

                            <Card.Body>

                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md={7}>
                                            {isLoad
                                                ? <h5><FontAwesomeIcon icon={faSpinner} spinPulse /> Loading...</h5>
                                                : CustomerInfo()
                                            }
                                        </Col>

                                        <Col md={5}>
                                            <Card.Title className="mb-3"><i className='fa-solid fa-file' /> Đơn hàng của bạn</Card.Title>

                                            <Row>
                                                <Col md={12}>
                                                    <InputGroup>
                                                        <FloatingLabel label={<>Mã giảm giá</>} >
                                                            <Form.Control type="text" value={metadata.discount_code} name="discount_code" onChange={handleInput} placeholder="Mã giảm giá" className={metadata.error_list.discount_code ? 'is-invalid' : ''} disabled={isLoadDiscount || isLoadSubmit} />
                                                        </FloatingLabel>
                                                        <Button type='button' variant='outline-secondary' onClick={handleCheckDiscount} disabled={isLoadDiscount || isLoadSubmit || metadata.discount_code.length === 0}>
                                                            {isLoadDiscount ? <FontAwesomeIcon icon={faSpinner} spinPulse /> : null} Kiểm tra
                                                        </Button>
                                                    </InputGroup>
                                                    <i className='text-danger'>{metadata.error_list.discount_code}</i>
                                                </Col>

                                                <Col md={12}>
                                                    <Table striped bordered hover>
                                                        <tbody>
                                                            <tr>
                                                                <th>Tổng tiền hàng</th>
                                                                <th className='text-end'>
                                                                    <CurrencyFormat value={cartTotal} displayType={'text'} thousandSeparator={true} suffix='₫' renderText={value => <span className='fw-bold'>{value}</span>} /><br></br>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th>Giảm giá {metadata.discount_type === 0 ? <i className='text-danger'>(-{metadata.discount_value}%)</i> : null}</th>
                                                                <th className='text-end'>
                                                                    <CurrencyFormat value={parseInt(metadata.discount_money)} displayType={'text'} thousandSeparator={true} suffix='₫' prefix={parseInt(metadata.discount_money) > 0 ? '-' : null} renderText={value => <span className='fw-bold'>{value}</span>} /><br></br>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th>Tổng đơn hàng</th>
                                                                <th className='text-end'>
                                                                    <CurrencyFormat value={cartTotal - parseInt(metadata.discount_money)} displayType={'text'} thousandSeparator={true} suffix='₫' renderText={value => <span className='fw-bold text-danger'>{value}</span>} /><br></br>
                                                                </th>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                    <div className='text-muted'><i className='text-danger'>*</i><i>(Chưa bao gồm phí vận chuyển.)</i></div>
                                                </Col>

                                                <Col md={12} className='cart-check-out'>
                                                    {!isLoadSubmit ?
                                                        <Link to={props.page === 'order' ? '/order/' : '/danh-muc/'} className='btn btn-primary' >
                                                            <FontAwesomeIcon icon={faCartPlus} />  Mua thêm
                                                        </Link>
                                                        : null
                                                    }


                                                    <Button variant='success float-end' type='submit' disabled={isLoadSubmit ? true : false}>
                                                        {isLoadSubmit ?
                                                            <><FontAwesomeIcon icon={faSpinner} spinPulse /> Đang xử lý...</>
                                                            :
                                                            <><FontAwesomeIcon icon={faCheck} /> Đặt hàng</>
                                                        }
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </>
                        :
                        <Card.Body>
                            <h5 className='text-muted'>Giỏ hàng không có sản phẩm!</h5>
                            <Link to={props.page === 'order' ? '/order' : '/danh-muc/'} className='btn btn-primary'>
                                <FontAwesomeIcon icon={faCartPlus} />  Mua thêm
                            </Link>
                        </Card.Body>
                    }
                </Card >
            </div >
        </>
    );
}

export default CartIndex;